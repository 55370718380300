import React from 'react';
import HEADER_PLACEHOLDER from './HEADER_PLACEHOILDER/';
import SCOTT from '../assets/SCOTT_upClose.JPG';
import {
  Wrapper,
  IMGWrapper,
  SocialButtons,
  Copy,
} from '../components/BIO/BIO';

const BIOScott = () => {
  return (
    <>
      <HEADER_PLACEHOLDER />
      <Wrapper>
        <IMGWrapper>
          <img src={SCOTT} alt="Scott Pagodin's headshot"></img>
        </IMGWrapper>
        <Copy>
          <h1>Scott Pagodin</h1>
          <h3>Principal</h3>
          <SocialButtons>
            <a
              href="https://www.linkedin.com/in/spagodin/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link to Scott Pagodin's linkedin"
            >
              <i className="fab fa-linkedin fa-lg"></i>
            </a>
            <a
              href="mailto:scott@gravelerc.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link to Scott Pagodin's email"
            >
              <i className="far fa-envelope fa-lg"></i>
            </a>
          </SocialButtons>
          <div>
            <p>
              Scott is a talented Team developer, Leader and highly skilled
              analytics expert with more than 20 years of experience leading
              teams, creating performance measurement systems, integrating
              systems and implementing problem solving solutions for both public
              and private organizations ranging from 5 to 900 units.
            </p>
            <p>
              Scott works with executive teams, boards, and management of
              companies, developing business plans, driving financial and
              operational turnarounds, and implementing large- scale performance
              improvement programs. He has partnered with Gravel since its
              formation and most recently created and lead the performance
              management function for Punch Bowl Social, where he led Business
              Analytics and Operations Technology initiatives within the
              emerging field of experiential dining as part of an organization
              recognized in the Top 50 of the most innovative companies in the
              world.
            </p>
            <p>
              Before joining Punch Bowl Social, Scott served BPV Capital
              Management as the Director of Business Analytics &amp; Marketing
              Operations, and Lirio as Director of Software Development. Earlier
              in his career, Scott led Enterprise Analysis and Business
              Intelligence for Ruby Tuesday when it was a publicly traded
              company with 700 units.
            </p>
            <p>
              Scott’s ability to deliver coordinated analysis that quickly
              identifies trends, problems and patterns while finding actionable
              solutions for performance issues, along with his leadership
              skills, creates a powerful advantage for companies looking to
              understand opportunities and forge strategic plans for improving
              performance and driving results.
            </p>
            <p>
              Scott’s vision and process for information analysis is refined
              through hands on experience and wrapped in the discipline of
              project management. Combined with outstanding leadership skills,
              Scott’s is an asset to any organization who is fortunate enough to
              leverage his expertise.
            </p>
          </div>
        </Copy>
      </Wrapper>
    </>
  );
};

export default BIOScott;
