import React from 'react';
import ItemWrapper from "../ItemWrapper"
import MIND from '../../../assets/mind-executive-services.png';


const ExecutiveSupport = () =>{

  const items = [
    {
      bold:"Interim/Fractional Leadership",
      copy: "- Immediate short-term placement"
    },
    {
      bold:"Communications",
      copy: "- Internal/External messaging format and frequency design"
    },
    {
      bold:"Executive Compensation Planning",
      copy: "- Development to reward organizational performance"
    },
    {
      bold:"Succession Planning",
      copy: "- Contingency replacement and transitional design management. Counsel for key positions (Founder/CEO/Senior Leadership)"
    },
    {
      bold:"Board Effectiveness",
      copy: "- Board/Management relationship, committee evaluations, governance, Executive and Board Compensation, and meeting preparation training"
    },
    {
      bold:"Crisis Response",
      copy: "- Immediate on-site deployment, internal communication and leadership coaching, liaison for directly impacted parties with professional counseling coordination, media response management and spokesman services"
    },
  ]
  return <ItemWrapper image={MIND} alt='mind icon' heading='EXECUTIVE SUPPORT' items={items}  />
}

export default ExecutiveSupport
