import React from 'react';
import HEADER_PLACEHOLDER from './HEADER_PLACEHOILDER/';
import ROB from '../assets/ROB.jpg';
import {
  Wrapper,
  IMGWrapper,
  SocialButtons,
  Copy,
} from '../components/BIO/BIO';

const BIORob = () => {
  return (
    <>
      <HEADER_PLACEHOLDER />
      <Wrapper>
        <IMGWrapper>
          <img src={ROB} alt="Rob LeBoeuf's headshot"></img>
        </IMGWrapper>
        <Copy>
          <h1>Rob LeBoeuf</h1>
          <h3>Founder and Principal</h3>
          <SocialButtons>
            <a
              href="https://www.linkedin.com/in/rob-leboeuf/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link to Rob LeBoeuf's linkedin"
            >
              <i className="fab fa-linkedin fa-lg"></i>
            </a>
            <a
              href="mailto:rob@gravelerc.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link to Rob LeBoeuf's email"
            >
              <i className="far fa-envelope fa-lg"></i>
            </a>
          </SocialButtons>
          <div>
            <p>
            Rob founded Gravel Executive Resources Consulting in 2013 as a natural extension of his expertise and
passion for developing results driven leaders. Rob has focused his career on becoming a leading expert
in many different facets of multiple industries (hospitality, construction, consulting, human resources),
to help organizations become more successful in driving performance and leading/motivating teams.
            </p>
            <p>
            Rob is a strategic and energizing leader who gets it done, improving organizations every step of
the way. Proven in conceptualizing and executing vision, while overseeing cross-functional
departments and teams. He is a Strong communicator, with a successful history in financial
management, human resources, operations, and compliance. His experience extends to both
the public and private sector, and includes Operations, Finance, Legal, Information Technology,
Human Resources, Compensation Planning, Training, Compliance, Internal Communications,
Crisis Management, Performance Measures, Organizational Development and Strategic
Planning. In addition to serving on private company Boards, Rob’s public company service
includes over 10 years of direct responsibility for the Nominating and Governance and
Compensation committees of the Board of Directors along with ownership of Board
Effectiveness Evaluations and Succession Planning.
            </p>
            <p>
            Throughout his career, Rob has demonstrated a talent for problem solving and process improvement
and has consistently been tasked with leading and improving multiple disciplines while dramatically
improving performance and preparing the companies he has served for rapid growth. He has
successfully applied his skills in organizations inside and outside of the hospitality industry leading
companies to become more successful in driving improved performance, building, and leading teams
and turning around companies in distress.
            </p>
            <p>
            Most recently, Rob held the roles of Chief Operating Officer and Chief Executive Officer for
Infuse Hospitality, a contract food and beverage company serving more than 25 million square
feet of office and retail space throughout North America. While turning the company around
and preparing it for sustainable growth, Rob stabilized and improved existing business
performance and built the processes, systems, team, and capabilities to prepare the company
for rapid growth.
              </p>
              <p>
              Prior to his time with Infuse Hospitality, Rob held the roles of Chief Operating Officer and Chief of Staff
for Punch Bowl Social; a “Best-in-Class” experiential restaurant concept founded in 2012. During his
tenure with Punch Bowl Social, Rob served the Company in the capacities of Chief People Officer,
Operations Strategy Officer, and President of Organizational Development prior to his appointment to
Chief Operating Officer and Chief of Staff. Rob served as the Chief People/Compliance Officer and Chief
of Staff for Ruby Tuesday, an international restaurant chain with over 700 locations and 40,000 Team
members.
              </p>
          </div>
        </Copy>
      </Wrapper>
    </>
  );
};

export default BIORob;
