import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, colors, fonts, FontScale, SpaceScale } from '../../theme';
import ProfileMagazine from '../../assets/profile-logo.png';
import RestaurantNews from '../../assets/nations-restaurant-news.png';
import FastCompany from '../../assets/fast-company-logo.png';
import ATD from '../../assets/ATD-logo.png';

const Wrapper = styled.div`
  a {
    text-decoration: none;
    color: ${colors.black};
    height: 100%;
  }

  background-color: ${colors.lightGrey};

  ${breakpoints.tablet} {
    padding: 0 0 100px 0;
  }

  section {
    border-top: 2px solid ${colors.orange};
    border-bottom: 2px solid ${colors.orange};

    margin: 0 ${SpaceScale(4)};

    ${breakpoints.tablet} {
      width: 80vw;
      margin: 0 auto;
    }
  }
  h4 {
    font-family: ${fonts.serif};
    font-size: ${FontScale(6)};
    color: ${colors.orange};
  }

  ul {
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    justify-content: space-evenly;
    font-size: ${FontScale(3)};
    text-decoration: none;
    list-style: none;
    font-family: ${fonts.sans};
    line-height: 2;
    text-align: left;
    margin-bottom: ${SpaceScale(7)};
  }

  li {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    align-items: center;
    border-radius: 5px;
    width: 100%;
    padding: ${SpaceScale(4)};
    box-shadow: 2px 1px 2px #d770454a;
    transition: 0.4s;
    background-color: #ffffff8c;

    ${breakpoints.tablet} {
      width: 200px;

      :hover {
        /* transform: rotate(2deg); */
        transform: scale(1.02);
        box-shadow: 3px 5px 23px ${colors.darkGrey};
        cursor: pointer;
      }
    }
  }

  h5 {
    font-family: ${fonts.serif};
    font-size: ${FontScale(3.5)};
    text-align: center;
    margin: 0;
  }

  p {
    text-align: left;
  }

  img {
    margin: 0;
    padding: 0;
    max-width: 200px;
    width: 100%;
    max-height: 150px;
  }
`;

const Awards = () => {
  return (
    <Wrapper id="awards">
      <section>
        <h4>AWARDS</h4>
        <ul>
          <li>
            <a
              href="https://profilemagazine.com/2012/ruby-tuesday/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={ProfileMagazine}
                alt="Profile Magazine logo"
                style={{ maxHeight: '30px', maxWidth: '120px' }}
              />
              <p>Two-Time Featured, Profile Magazine (2012 & 2017)</p>
            </a>
          </li>
          <li>
            <a
              href="https://www.fastcompany.com/company/punch-bowl-social"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={FastCompany}
                alt="Fast Company logo"
                style={{ marginTop: '-20px' }}
              />
              <p style={{ marginTop: '-10px' }}>
                50 most Innovative Companies, Fast Company 2018 (Punch Bowl
                Social)
              </p>
            </a>
          </li>
          <li>
            <a
              href="https://www.nrn.com/emerging-chains/hot-concepts-2018-punch-bowl-social/gallery?slide=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={RestaurantNews} alt="Restaurant News logo" />
              <p>
                2015 Breakout Brand, Nation’s Restaurant News (Punch Bowl
                Social)
              </p>
              <p>
                Hot Concept Awards, Nation’s Restaurant News, 2018 (Punch Bowl
                Social)
              </p>
            </a>
          </li>
          <li>
            <a
              href="https://www.restaurantnewsresource.com/article24767.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={ATD}
                alt="American Society for Training and Development logo"
              />
              <p>
                Recipient of the American Society for Training and Development
                (ATD) top 10 company training and development award with Ruby
                Tuesday.
              </p>
            </a>
          </li>
        </ul>
      </section>
    </Wrapper>
  );
};

export default Awards;
