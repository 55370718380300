import { css } from "@emotion/core";
import normalize from "emotion-normalize";
import { breakpoints } from "./theme";

const fontFace = css`
  ${normalize}

  :global {
    @font-face {
      font-family: 'handbook-black';
      src: url('/fonts/pfhandbookpro_black-webfont.eot');
      src: url('/fonts/pfhandbookpro_black-webfont.eot?#iefix') format('embedded-opentype'),
        url('/fonts/pfhandbookpro_black-webfont.woff') format('woff'),
        url('/fonts/pfhandbookpro_black-webfont.ttf') format('truetype'),
        url('/fonts/pfhandbookpro_black-webfont.svg#pf_handbook_problack') format('svg');
      font-weight: normal;
      font-style: normal;
    }
    
    @font-face {
      font-family: 'handbook';
      src: url('/fonts/pfhandbookpro_light-webfont.eot');
      src: url('/fonts/pfhandbookpro_light-webfont.eot?#iefix') format('embedded-opentype'),
        url('/fonts/pfhandbookpro_light-webfont.woff') format('woff'),
        url('/fonts/pfhandbookpro_light-webfont.ttf') format('truetype'),
        url('/fonts/pfhandbookpro_light-webfont.svg#pf_handbook_prolight') format('svg');
      font-weight: normal;
      font-style: normal;
    }

    @font-face {
      font-family: 'handbook-italic';
      src: url('/fonts/pfhandbookpro_lightitalic-webfont.eot');
      src: url('/fonts/pfhandbookpro_lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
        url('/fonts/pfhandbookpro_lightitalic-webfont.woff') format('woff'),
        url('/fonts/pfhandbookpro_lightitalic-webfont.ttf') format('truetype'),
        url('/fonts/pfhandbookpro_lightitalic-webfont.svg#pf_handbook_prolight_italic') format('svg');
      font-weight: normal;
      font-style: normal;
    }

    @font-face {
      font-family: "Helvetica Now";
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url("/fonts/helvetica-now-regular.woff2") format("woff2"),
        url("/fonts/helvetica-now-regular.woff") format("woff");
    }

    @font-face {
      font-family: "Helvetica Now";
      font-style: italic;
      font-weight: 400;
      font-display: swap;
      src: url("/fonts/helvetica-now-italic.woff2") format("woff2"),
        url("/fonts/helvetica-now-italic.woff") format("woff");
    }

    @font-face {
      font-family: "Helvetica Now";
      font-style: normal;
      font-weight: 600;
      font-display: swap;
      src: url("/fonts/helvetica-now-medium.woff2") format("woff2"),
        url("/fonts/helvetica-now-medium.woff") format("woff");
    }

    @font-face {
      font-family: "Helvetica Now";
      font-style: normal;
      font-weight: 800;
      font-display: swap;
      src: url("/fonts/helvetica-now-bold.woff2") format("woff2"),
        url("/fonts/helvetica-now-bold.woff") format("woff");
    }
  }

  body,
  html {
    font-size: 16px;
    font-family: Helvetica Now, Helvetica Neue, system-ui, -apple-system,
      BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
  }
  h2,h3 {
    font-weight: 300;
  }

  ${breakpoints.mobile} {
    padding-right: 100px;
  }
`;

export default fontFace;
