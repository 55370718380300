import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Button from '../Button';
import logo from '../../assets/gravel-on-navy.svg';
import { colors, fonts, FontScale, SpaceScale, breakpoints } from '../../theme';

const Nav = styled.header`
  display: flex;
  justify-content: flex-end;
  justify-items: center;
  align-items: center;
  padding: ${SpaceScale(4)};
  width: 100vw;
  font-family: ${fonts.sans};

  p {
    color: ${colors.white};
    font-size: ${FontScale(5)};
    font-family: ${fonts.sans};
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const Brand = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  margin-left: ${SpaceScale(3.5)};

  img {
    width: 200px;
  }
  h3 {
    margin: 0;
    color: ${colors.orange};
    font-size: ${FontScale(2)};
    margin-left: ${SpaceScale(5.5)};
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: inherit;
  }

  div {
    display: flex;
    align-items: flex-end;
  }

  p {
    font-size: ${FontScale(2)};
    margin: 0 0 -3px 2px;
  }
`;

const Menu = styled.nav`
  display: flex;

  align-items: center;
  justify-content: flex-end;
  /* flex-direction: column-reverse; */
  justify-items: center;

  a {
    padding: ${SpaceScale(3)};
    text-decoration: none;
    color: ${colors.white};
    mix-blend-mode: exclusion;
    cursor: pointer;
    transition: 0.5s;
    :hover {
      transform: scale(1.1);
    }
  }

  ${breakpoints.tablet} {
    padding: ${SpaceScale(4)};
    flex-direction: row;
    align-items: center;

    a {
      padding: 0 ${SpaceScale(4)};
    }
    button {
      margin-left: ${SpaceScale(5)};
    }
  }
`;

const Header = () => {
  const [isHeroInView, setIsHeroInView] = useState(false);

  function checkHeight() {
    if (window.pageYOffset >= 700) {
      setIsHeroInView(true);
    } else {
      setIsHeroInView(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', checkHeight);
    return () => window.removeEventListener('scroll', checkHeight);
  });

  return (
    <Nav
      style={
        isHeroInView
          ? { backgroundColor: colors.black, justifyContent: 'space-between' }
          : null
      }
    >
      {isHeroInView && (
        <Brand>
          <a href="#top">
            <img src={logo} alt="Gravel logo" />
          </a>
        </Brand>
      )}

      <Menu>
        <a href="/#process">Process</a>
        <a href="/#capabilities">Capabilities</a>
        <a href="/#about-us">Our Team</a>
        <Button text="Let's Talk" />
      </Menu>
    </Nav>
  );
};

export default Header;
