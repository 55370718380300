import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, colors, FontScale, SpaceScale } from '../../theme';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px auto;

  h1 {
    text-align: center;
  }

  text-align: left;
  max-width: 1000px;
  background-color: ${colors.white};
  line-height: 1.5;
  box-shadow: 4px 2px 4px 2px ${colors.lightGrey};
  border-radius: 5px;
  padding: ${SpaceScale(5)};

  div {
    widows: 100%;
  }

  h2 {
    font-size: ${FontScale(6)};
    color: ${colors.orange};
  }

  a {
    color: ${colors.orange};
    text-decoration: none;
  }

  table {
    border-collapse: collapse;
  }

  thead {
    th {
      ${breakpoints.mobile} {
        font-size: ${FontScale(3)};
      }
    }
  }
  tbody {
    td {
      max-width: 500px;
      font-size: ${FontScale(3)};
    }

    li {
      ${breakpoints.mobile} {
        font-size: ${FontScale(2)};
      }
    }

    tr {
      border-bottom: 1px solid black;
    }
  }
  ${breakpoints.desktop} {
    /* height: 80vh; */
  }

  ${breakpoints.mobile} {
    max-width: 100vw;
  }
`;

const Wrapper = ({ children }) => {
  return <Card>{children}</Card>;
};

export default Wrapper;
