import React from 'react';
import styled from '@emotion/styled';
import { colors, fonts, FontScale, SpaceScale, breakpoints } from '../../theme';
import Button from '../Button';
import 'what-input';
import {
  Accordion,
} from 'react-accessible-accordion';

//  removes border collor for click
import '../../accessible-accordian.css';
//  accordian header logos

import OrganizationalDevelopment from "./SolutionSections/OrganizationalDevelopment"
import ExecutiveSupport from "./SolutionSections/ExecutiveSupport"
import StrategicPlanning from "./SolutionSections/StrategicPlanning"
import OperationalEffectivenessAndResultsMaximization from "./SolutionSections/OperationalEffectivenessAndResultsMaximization"
import OperationalAssessmentAndSupport from "./SolutionSections/OperationalAssessmentAndSupport"
import MergerAndAcquisitionSupport from "./SolutionSections/MergerAndAcquisitionSupport"

const OurSolutions = () => {

  return (
    <Wrapper>
      <Title id="capabilities">
        <h3>Our Capabilities</h3>
        <p>Customized support and applied expertise to fill your organization’s needs.</p>
      </Title>
      <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
        <Grid>
          <OrganizationalDevelopment />
          <ExecutiveSupport />
          <StrategicPlanning />
          <OperationalEffectivenessAndResultsMaximization />
          <OperationalAssessmentAndSupport />
          <MergerAndAcquisitionSupport />
        </Grid>
      </Accordion>
      <CTA>
        <Button text="Let's Talk" />
      </CTA>
    </Wrapper>
  );
};

const Grid = styled.section`
  padding: 50px 10px;

  ${breakpoints.tablet} {
    padding: ${SpaceScale(6)} ${SpaceScale(7)};
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
  }
`;

const CTA = styled.div`
  justify-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0 100px 0;

  h3 {
    font-family: ${fonts.serif};
  }

  button {
    background: ${colors.orange};
    color: white;

    ${breakpoints.mobile} {
      width: 90%;
    }
  }
`;


const Title = styled.div`
  padding: 100px 10px 0 10px;

  p {
    font-family: ${fonts.sans};
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.7;
  }
  h3 {
    color: ${colors.orange};
    font-family: ${fonts.serif};

    ${breakpoints.tablet} {
      font-size: ${FontScale(6)};
    }
  }
`;

const Wrapper = styled.section`
  background-color: ${colors.white};
`;

export default OurSolutions;
