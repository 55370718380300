import React from 'react';
import ItemWrapper from "../ItemWrapper"
import MONEY from '../../../assets/money-mergers-acq.png';

const MergerAndAcquisitionSupport = () =>{

  const items = [
    {
      bold:"Diligence Process Management",
      copy: ""
    },
    {
      bold:"Business Process Standardization",
      copy: ""
    },
    {
      bold:"Operational Assessment & Optimization",
      copy: ""
    },
    {
      bold:"G&A Cost Reduction & Restructuring",
      copy: ""
    },
    {
      bold:"Business & Financial Modeling",
      copy: ""
    },
    {
      bold:"Internal Controls",
      copy: "- Assessment and loss prevention/mitigation"
    },
    {
      bold:"Organizational Integration",
      copy: "for Support and Operations"
    },
  ]
  return <ItemWrapper image={MONEY} alt='money changing hands icon' heading='MERGER & ACQUISITION SUPPORT' items={items}  />
}

export default MergerAndAcquisitionSupport
