import React from 'react';
import styled from '@emotion/styled';
import Button from '../Button';
import headerImg from '../../assets/header.jpg';
import { colors, fonts, FontScale, SpaceScale, breakpoints } from '../../theme';
import Fade from 'react-reveal/Fade';
import bigLogo from '../../assets/gravel-on-navy.svg';

const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 60vh;
  padding: ${SpaceScale(3)};
  background: 50% 0px no-repeat;
  background-size: cover;
  background-image: linear-gradient(
      rgba(35, 35, 36, 0.75),
      rgba(35, 35, 36, 0.85)
    ),
    url(${headerImg});

  padding: ${SpaceScale(5)};

  ${breakpoints.tablet} {
    justify-content: center;
    height: 110vh;
    background-attachment: fixed;
    margin-top: -150px;
    align-items: center;
    padding: ${SpaceScale(7.5)} ${SpaceScale(8.5)};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  max-width: 700px;

  /* margin-top: -170px; */

  span {
    font-size: 8px;
    vertical-align: 3px;
  }

  div {
    display: flex;
    justify-content: center;

    ${breakpoints.tablet} {
      /* margin-left: 100px; */
    }

    /* p {
      font-size: ${FontScale(2)};
      font-family: ${fonts.sans};
      align-self: center;
      padding:0;
      padding-left: 2px;
      
      ${breakpoints.mobile} {
        margin: 0;
      }
    } */
  }
  h1 {
    color: ${colors.orange};
    font-family: ${fonts.serif};
    margin: 0;
    font-size: ${FontScale(3)};
    font-weight: 300;
    padding: 0 0 ${SpaceScale(4)} 0;
  }

  p {
    /* text-transform: uppercase; */
    margin: 0;
    padding: 0 0 ${SpaceScale(3)} 0;
    font-family: ${fonts.sans};
    color: white;
    font-size: ${FontScale(3.5)};
    max-width: 280px;
  }
  img {
    margin-bottom: 10px;
    width: 141px;
  }

  ${breakpoints.mobile} {
    h1 {
      width: 200px;
    }

    img {
      height: 30px;
      width: 141px;
    }
  }

  ${breakpoints.tablet} {
    margin-top: 150px;
    margin-left: -150px;
    span {
      font-size: 12px;
      vertical-align: 15px;
    }

    h1 {
      font-size: ${FontScale(6)};
      /* padding: 0 0 ${SpaceScale(5)} 0; */
    }

    p {
      font-size: ${FontScale(5)};
      line-height: 1.2;
      max-width: 450px;
    }

    img {
      margin-bottom: 30px;
      width: 270px;
    }
  }
`;

const Hero = () => {
  return (
    <Title id="top">
      <Fade right>
        <Content>
          <div>
            <img src={bigLogo} alt="Gravel logo" />
          </div>
          <p>
            Providing leadership resources to Executives and results-oriented
            organizations since 2013
          </p>
          <h1>
            Add Gravel<span>&reg;</span> to your mix
          </h1>
          <Button text="Let's talk" />
        </Content>
      </Fade>
    </Title>
  );
};

export default Hero;
