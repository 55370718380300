import React, { useState, useEffect } from 'react';
import { Global } from '@emotion/core';
import globalStyles from './globalStyles';
import Headroom from 'react-headroom';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// import { PrivacyPolicy, DataUsage, Terms, Partners } from './routes'
import PrivacyPolicy from './routes/PrivacyPolicy';
import Terms from './routes/Terms';
import ScrollToTop from './components/HELPERS/ScrollToTop';

import './App.css';
import Header from './components/Header';
import HeaderMobile from './components/Header/HeaderMobile';
import Hero from './components/Hero';
import Footer from './components/Footer/Footer';
import Contact from './components/Contact/Contact';
import AboutUs from './components/AboutUs/AboutUs';
import Process from './components/Process/Process';
import Awards from './components/Awards/Awards';
import OurSolutions from './components/OurSolutions/OurSolutions';
import BIORob from './routes/BIORob';
import BIOScott from './routes/BIOScott';
// import BIOLeah from './routes/BIOLeah';

function App() {
  const [isTablet, setTablet] = useState(window.innerWidth > 767);

  const updateMedia = () => {
    setTablet(window.innerWidth > 767);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <div className="App">
      <Global styles={globalStyles} />

      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            {isTablet ? (
              <Headroom>
                <Header />
              </Headroom>
            ) : (
              <HeaderMobile />
            )}
            <Hero />
            <Process />
            <OurSolutions />
            <AboutUs />
            <Awards />
            <Contact />
          </Route>
          <Route path="/Terms">
            {isTablet ? (
              <Headroom>
                <Header />
              </Headroom>
            ) : (
              <HeaderMobile />
            )}
            <Terms />
          </Route>
          <Route path="/PrivacyPolicy">
            {isTablet ? (
              <Headroom>
                <Header />
              </Headroom>
            ) : (
              <HeaderMobile />
            )}
            <PrivacyPolicy />
          </Route>

          <Route path="/Bio-Rob">
            {isTablet ? <Header /> : <HeaderMobile />}
            <BIORob />
          </Route>
          <Route path="/Bio-Scott">
            {isTablet ? <Header /> : <HeaderMobile />}
            <BIOScott />
          </Route>
          {/* <Route path="/Bio-Leah">
            {isTablet ? <Header /> : <HeaderMobile />}
            <BIOLeah />
          </Route> */}
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
