import React from 'react';
import styled from '@emotion/styled';
// import Button from '../Button'
// import headerImg from "../../assets/header.jpg"
import { colors, fonts, FontScale, SpaceScale, breakpoints } from '../../theme';

const Form = styled.div`
  display: flex;
  margin: 0 auto;
  /* height: 85vh; */
  flex-direction: column;
  /* justify-content: center; */
  /* text-align: center; */
  background-color: ${colors.form};
  font-family: ${fonts.serif};
  color: ${colors.orange};
  padding: 0 ${SpaceScale(3)};

  fieldset {
    border: none;
  }

  input,
  textarea,
  button {
    width: 100%;
    margin: ${SpaceScale(3)};
    padding: ${SpaceScale(3)};
    text-align: left;
    vertical-align: middle;
    resize: none;
    font-size: ${FontScale(4.5)};
    line-height: 1.1em;
    color: #d67049;
    background: #fdfdfe;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${colors.orange};
      opacity: 0.5; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${colors.orange};
      opacity: 0.5;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${colors.orange};
      opacity: 0.5;
    }

    :focus {
      outline: none;
    }
  }

  h2 {
    font-size: ${FontScale(6.5)};
    font-weight: 200;
  }

  form {
    /* height: 50vh; */
    max-width: 750px;
    /* width: 50vw; */
  }

  div {
    display: flex;
  }

  textarea {
    height: 200px;
  }

  button {
    width: ${SpaceScale(10)};
    height: ${SpaceScale(6)};
    color: ${colors.white};
    background-color: ${colors.orange};
    font-size: ${FontScale(4.5)};
    text-align: center;
    transition: 0.2s;

    :hover {
      cursor: pointer;
      /* opacity: .95; */
      box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    }

    ${breakpoints.tablet} {
      width: ${SpaceScale(10)};
    }
  }

  ${breakpoints.tablet} {
    height: 80vh;
    align-items: center;
    /* justify-content: center; */
    fieldset {
      width: 750px;
    }
  }

  ${breakpoints.desktop} {
    max-height: 750px;
  }
`;

const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  height: 200px;
  padding: 50px ${SpaceScale(3)};
  /* max-width: 350px; */
  background-color: ${colors.form};

  ${breakpoints.tablet} {
    padding: 125px ${SpaceScale(3)};
    /* margin: 0 auto ${SpaceScale(6)} auto ; */
    /* height: 300px; */
  }
  /* ${breakpoints.desktop} {
    padding: 125px ${SpaceScale(3)};
  } */

  h2 {
    max-width: 700px;
    color: ${colors.orange};
    font-family: ${fonts.serif};
    font-size: ${FontScale(5)};

    ${breakpoints.tablet} {
      font-size: ${FontScale(7)};
    }
  }
`;

const Contact = () => {
  return (
    <>
      <Heading id="form">
        <h2>
          Let's discuss the best approach to get your business strategically
          positioned for dynamic results.
        </h2>
      </Heading>
      <Form>
        <h2>Let's Talk</h2>
        <form name="Gravel Intake Form" method="POST" data-netlify="true">
          <fieldset>
            <input type="hidden" name="form-name" value="Gravel Intake Form" />
            <div>
              <label htmlFor="firstName" hidden>
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                aria-label="first name"
                id="firstName"
                placeholder="First Name"
                required
              />
              <label htmlFor="lastName" hidden>
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                aria-label="last name"
                id="lastName"
                placeholder="Last Name"
              />
            </div>
            <div>
              <label htmlFor="email" hidden>
                Your Email
              </label>
              <input
                type="email"
                name="email"
                aria-label="email"
                id="email"
                placeholder="Email"
                required
              />
              <label htmlFor="phone" hidden>
                Your Phone
              </label>
              <input
                type="text"
                name="phone"
                aria-label="phone number"
                id="phone"
                placeholder="Phone Number"
              />
            </div>
            <div>
              <label htmlFor="Company" hidden>
                Company Name
              </label>
              <input
                type="text"
                name="company"
                aria-label="company name"
                id="company"
                placeholder="Company Name"
              />
              <label htmlFor="subject" hidden>
                Subject
              </label>
              <input
                type="text"
                name="subject"
                aria-label="subject title"
                id="subject"
                placeholder="Subject"
              />
            </div>
            <div>
              <label htmlFor="message" hidden>
                Message
              </label>
              <textarea
                name="message"
                id="message"
                aria-label="your message"
                placeholder="Message"
                required
              ></textarea>
            </div>
          </fieldset>
          <button type="submit">Send</button>
        </form>
      </Form>
    </>
  );
};

export default Contact;
