import React from 'react';
import styled from '@emotion/styled';
import { FontScale, SpaceScale, breakpoints, fonts, colors } from '../../theme';
import unDrawTL from '../../assets/topLeft.png';
import unDrawM from '../../assets/middle.png';
import unDrawBL from '../../assets/bottomLeft.png';

const Process = () => {
  return (
    <Wrapper id="process">
      <Title>Explore your foundational possibilities and redefine forward motion.</Title>
      <div>
        <img src={unDrawTL} alt="" />
        <p>Today's world of business looks vastly different than in previous months and recent years. Business owners and <strong>industry leaders are required to do more with less and make difficult choices about the resources and expertise that drive excellence</strong>  in execution and continued business growth. Compounded by an ever-changing landscape of new rules, unforeseen consumer behaviors, and decreased margins, the opportunity for risk can be great. Gravel has been helping Executives navigate these and many other challenges since 2013.</p>
      </div>
      <div>
        <p>Gravel Executive Resources helps Executives refine and execute a roadmap for fast and sustainable performance outcomes <strong>Our team will help you define strategies, implement tactical efficiencies, and drive fast and sustainable results</strong> through specific and measured steps while developing your team and organizational competencies. Gravel has the resources to fill in the gaps to optimize performance and will work with you to build a strong foundation for continued success.</p>
        <img src={unDrawM} alt="" />
      </div>
      <div>
        <img src={unDrawBL} alt="" />
        <p>You are in control; you choose your path. <strong>Gravel will help you build a prescriptive resource plan, with as much support as you need, or as little support as you require.</strong> The team at Gravel works from ideation to implementation, programming to performance measures.</p>
      </div>
      <Title>Add Gravel<span>®</span> to your mix</Title>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  margin: 0 auto;
  font-family: ${fonts.sans};
  border-bottom: 2px solid ${colors.orange};

  margin: 0 ${SpaceScale(4)};

  /* ${breakpoints.tablet} {
    } */

  ${breakpoints.tablet} {
    margin: 0 auto;
    /* padding: ${SpaceScale(4)}; */
    width: 85vw;
  }

  svg {
    width: 95vw;

    ${breakpoints.mobile} {
      margin-top: -150px;
    }
  }

  img {
    max-width: 200px;

    ${breakpoints.tablet} {
      max-width: 300px;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    /* margin: ${SpaceScale(3)} auto ${SpaceScale(6)} auto; */

    ${breakpoints.mobile} {
      margin-bottom: 50px;
    }

    :nth-of-type(2) {
      ${breakpoints.mobile} {
        flex-direction: column-reverse;
      }
    }

    ${breakpoints.tablet} {
      flex-direction: row;
      justify-content: space-evenly;
      /* margin: ${SpaceScale(6)} 0; */
    }
  }

  p {
    max-width: 600px;
    margin: 0 ${SpaceScale(5)};
    text-align: left;
    font-size: ${FontScale(4)};
    line-height: 1.15;
    color: #3f3d56;

    ${breakpoints.tablet} {
      /* font-size: ${FontScale(4.5)}; */
      line-height: 1.5;
    }
  }
`;

const Title = styled.h2`
  color: ${colors.orange};
  font-family: ${fonts.serif};
  margin: 0 ${SpaceScale(4)};

  span:first-of-type {
    vertical-align: 15px;
    font-size:12px;
  }

  ${breakpoints.tablet} {
    font-size: ${FontScale(6)};
    margin-bottom: ${SpaceScale(5)};
  }

  &:first-of-type {
    padding: 50px 0;
  }

`;

export default Process;
