import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, fonts, FontScale, SpaceScale } from '../../theme';

const HAMBURGER = styled.button`
  position: fixed;
  top: 10px;
  right: 5px;
  padding: 15px;
  background: transparent;
  outline: none;
  border: none;
  color: ${colors.orange};
  transition: 0.5s;
`;

const Nav = styled.header`
  display: flex;
  justify-content: flex-end;
  justify-items: center;
  align-items: center;
  padding: ${SpaceScale(4)};
  width: 100vw;

  p {
    color: ${colors.white};
    font-size: ${FontScale(5)};
    font-family: ${fonts.sans};
    text-transform: uppercase;
    font-weight: 400;
  }

  position: fixed;
  justify-content: center;
`;

const Menu = styled.nav`
  position: fixed;
  background: ${colors.black};
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  transition: 0.5s;

  a {
    padding: ${SpaceScale(3)};
    text-decoration: none;
    color: ${colors.white};
    mix-blend-mode: exclusion;
    cursor: pointer;
  }
`;

const HeaderMobile = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Nav>
      <Menu
        onClick={handleClick}
        style={isOpen ? { top: '0' } : { top: '-170px' }}
      >
        <a href="/#process">Process</a>
        <a href="/#solutions">Solutions</a>
        <a href="/#about-us">Our Team</a>
      </Menu>
      <HAMBURGER
        onClick={handleClick}
        style={
          isOpen
            ? { transform: 'rotate(0deg)', color: colors.white }
            : { transform: 'rotate(90deg)' }
        }
      >
        |||
      </HAMBURGER>
    </Nav>
  );
};

export default HeaderMobile;
