import React from 'react'
import styled from '@emotion/styled';
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { colors, fonts, FontScale, SpaceScale, breakpoints } from '../../theme';

const ItemWrapper = ({ image, alt, heading, items}) => {
  return (
    <AccordionItem>
      <Item>
        <AccordionItemHeading>
          <AccordionItemButton>
            <AccButton>
              <div>
                <img src={image} alt={alt}></img>
                <h2>{heading}</h2>
              </div>
            </AccButton>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <ul>
            {items.map(({bold,specialCopy, copy}) => (
              <li key={bold}><strong>{bold}</strong> <span>{specialCopy}</span> {copy}</li>
            ))}
          </ul>
        </AccordionItemPanel>
      </Item>
    </AccordionItem>
  )
}
//  styling button
const AccButton = styled.div`
  display: flex;
  margin-top: 50px;
  transition: 0.5s;
  margin: 15px 0 15px 15px;

  ${breakpoints.mobile} {
    padding: 0 5px;
    margin-left: 60px;
    justify-items: center;
    align-items: center;
    align-content: center;
    margin: 15px 0 15px 8px;
  }

  img {
    max-height: 20px;
    margin-right: 20px;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  p {
    color: ${colors.darkGrey};
    font-weight: bold;
    margin-left: 60px;
    align-self: flex-end;
    ${breakpoints.mobile} {
    }
  }
`;

const Item = styled.div`
  max-width: 700px;
  text-align: left;
  transition: 0.4s;
  margin: 0 20px;
  box-shadow: 5px 2px 10px 3px #4c4c4e1c;

  &:hover {
    transform: scale(1.03);
  }

  /* FOR ACCORDIAN STYLE DESKTOP */
  background-color: ${colors.white};
  border-radius: 10px;

  ${breakpoints.mobile} {
    padding: 0 5px;
    max-width: 95vw;
    border-radius: 10px;

    ul {
      font-size: ${FontScale(3.5)};
      margin-left: 15px;
      margin: 15px;
      padding-bottom: 150px;
    }
  }

  h2 {
    font-family: ${fonts.serif};
    margin: 0;
    font-size: ${FontScale(4.5)};
    padding: 10px 0;

    ${breakpoints.mobile} {
      font-size: ${FontScale(4)};
      line-height: 1.5;
      border-radius: 5px;
    }
  }

  li {
    list-style: none;
    margin-bottom: ${SpaceScale(3)};
  }

  ul {
    padding: 0 20px;
    margin-bottom: 15px;
  }

  span {
    white-space: nowrap;
  }
`;

export default ItemWrapper
