import React from 'react';
import styled from '@emotion/styled';
import { colors, fonts, SpaceScale, breakpoints, FontScale } from '../../theme';
import Text from '../Text';
import ROB from '../../assets/ROB.jpg';
// import LEAH from '../../assets/leahLG.jpg';
import SCOTT from '../../assets/SCOTT_upClose.JPG';
import { Link } from 'react-router-dom';

const Wrapper = styled.section`
  background-color: ${colors.lightGrey};
  padding: ${SpaceScale(3)} ${SpaceScale(2)};

  h2 {
    font-family: ${fonts.serif};
    font-size: ${FontScale(6)};
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoints.tablet} {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${SpaceScale(5)} 0;

  img {
    border-radius: 10px;
    object-fit: cover;
    width: 250px;
    height: 300px;
    transition: 0.5s;

    :hover {
      transform: scale(1.03);
      box-shadow: 3px 5px 23px ${colors.darkGrey};
    }
  }

  h3 {
    color: ${colors.orange};
    padding: 0;
    margin: 0;
  }

  p {
    margin: 0;
    max-width: 350px;
    color: ${colors.black};
    text-align: left;
  }

  a {
    text-decoration: none;
    color: black;
    padding: ${SpaceScale(3)};
  }

  div {
    padding: 0;
    display: flex;
    margin-top: ${SpaceScale(2)};
    max-width: 100%;
    width: 150px;
    justify-content: space-evenly;
  }

  ul {
    text-align: left;
    width: 90%;
    list-style: square;
    padding: 0;
  }

  li {
    list-style: none;
    margin-bottom: 10px;
  }
`;

const JobDescription = styled.p`
  padding-top: 5px;
  margin: 0;
`;

const AboutUs = () => {
  return (
    <Wrapper id="about-us">
      <Text>
        Gravel helps fill in the gaps with experienced, industry leading
        resources.
      </Text>
      <Grid>
        <Card>
          <Link to="/Bio-Rob">
            <img src={ROB} alt="Rob LeBoeuf's headshot"></img>
          </Link>
          <h3>Rob LeBoeuf</h3>
          <JobDescription>Founder and Principal</JobDescription>
          <div>
            <a
              href="https://www.linkedin.com/in/rob-leboeuf/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link to Rob LeBoeuf's LinkedIn"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <Link to="/Bio-Rob">
              <i
                className="fas fa-globe"
                aria-label="Link to Rob LeBoeuf's Bio"
              ></i>
            </Link>
            <a
              href="mailto:rob@gravelerc.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link to Rob LeBoeuf's Email"
            >
              <i className="far fa-envelope"></i>
            </a>
          </div>
        </Card>
        {/* <Card>
          <Link to="/Bio-Leah">
            <img src={LEAH} alt="Leah Grimm's headshot"></img>
          </Link>
          <h3>Leah Grimm</h3>
          <JobDescription>Principal</JobDescription>
          <div>
            <a
              href="https://www.linkedin.com/in/leahmariegrimm/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link to Leah Grimm's LinkedIn"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <Link to="/Bio-Leah">
              <i
                className="fas fa-globe"
                aria-label="Link to Leah Grimm's Bio"
              ></i>
            </Link>
            <a
              href="mailto:leah@gravelerc.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link to Leah Grimm's Email"
            >
              <i className="far fa-envelope"></i>
            </a>
          </div>
        </Card> */}
        <Card>
          <Link to="/Bio-Scott">
            <img src={SCOTT} alt="Scott Pagodin's headshot"></img>
          </Link>
          <h3>Scott Pagodin</h3>
          <JobDescription>Principal</JobDescription>
          <div>
            <a
              href="https://www.linkedin.com/in/spagodin/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link to Scott Pagodin's LinkedIn"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <Link to="/Bio-Scott">
              <i
                className="fas fa-globe"
                aria-label="Link to Scott Pagodin's Bio"
              ></i>
            </Link>
            <a
              href="mailto:scott@gravelerc.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link to Scott Pagodin's Email"
            >
              <i className="far fa-envelope"></i>
            </a>
          </div>
        </Card>
      </Grid>
    </Wrapper>
  );
};

export default AboutUs;
