import { FontScale } from "./scale";

export const fontSizes = FontScale().map((step) => `${step}rem`);

export const fonts = {
  serif:'Roboto, "Helvetica Neue", Helvetica, Arial, serif',
  sansOption2: "'Roboto', sans-serif;",
  sans: "futura-pt, sans-serif;",
};




  



// Book:
// font-family: futura-pt, sans-serif;
// font-weight: 400;
// font-style: normal;

// Italic:
// font-family: futura-pt, sans-serif;
// font-weight: 400;
// font-style: italic;

// Heavy:
// font-family: futura-pt, sans-serif;
// font-weight: 700;
// font-style: normal;

// Heavy Italic:
// font-family: futura-pt, sans-serif;
// font-weight: 700;
// font-style: italic;
