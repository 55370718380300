import React from 'react';
import ItemWrapper from "../ItemWrapper"
import GRAPH from '../../../assets/graph-results-mgmt.png';

const OperationalEffectivenessAndResultsMaximization = () =>{

  const items = [
    {
      bold:"Sales & Marketing",
      copy: "- End-to-end sales process review and program improvement"
    },
    {
      bold:"Margin Optimization",
      copy: "- Revenue capture, cost control/loss prevention to maximize flow-through"
    },
    {
      bold:"KPI Development",
      copy: "to identify and teach the actions that drive performance."
    },
    {
      bold:"Goal Creation",
      copy: "and cascaded objectives by business unit/department, including key performance indicators key performance indicators (KPI)"
    },
    {
      bold:"Reporting Design",
      copy: "to track KPIs and ensure ROI"
    },
    {
      bold:"Performance-Based Compensation",
      copy: "- Market pay positioning and self-funding bonus plans to motivate performance"
    },
  ]
  return <ItemWrapper image={GRAPH} alt='graph icon' heading='OPERATIONAL EFFECTIVENESS & RESULTS MAXIMIZATION' items={items}  />
}

export default OperationalEffectivenessAndResultsMaximization
