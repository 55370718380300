import React from 'react';
import styled from '@emotion/styled';
import { colors, fonts, FontScale, SpaceScale, breakpoints } from '../../theme';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  /* height: 200px; */
  padding: 0 ${SpaceScale(3)};
  max-width: 350px;

  ${breakpoints.tablet} {
    /* margin: 0 auto ${SpaceScale(6)} auto ; */
    /* height: 300px; */
    max-width: 700px;
  }

  h2 {
    color: ${colors.orange};
    font-family: ${fonts.serif};
    font-size: ${FontScale(5)};

    ${breakpoints.tablet} {
      font-size: ${FontScale(7)};
    }
  }
`;

const Text = ({ children }) => {
  return (
    <Wrapper>
      <h2>{children}</h2>
    </Wrapper>
  );
};

export default Text;
