import React from 'react';
import HEADER_PLACEHOLDER from './HEADER_PLACEHOILDER/';
import Wrapper from './HEADER_PLACEHOILDER/Wrapper';

const PrivacyPolicy = () => {
  return (
    <>
      <HEADER_PLACEHOLDER />
      <Wrapper>
        <div id="top">
          <h1>Privacy Policy</h1>
          <p>
            <em>Effective date: October 08, 2020</em>
          </p>
          <p>
            Gravel Executive Resource Consulting, LLC (“Gravel,” “our,” or “we”)
            operates the website made available at gravelerc.com, including the
            features available through the site (collectively, the “Services”).
            Gravel values and respects the privacy of its customers and
            visitors. The purpose of this Privacy Policy is to provide you with
            information about Gravel’s policies regarding the collection,
            processing, use, storage, and disclosure of information when you use
            our Services. This Privacy Policy also describes the choices you can
            make about our use of your information.
          </p>
          <p>
            Please note that this Privacy Policy only applies to the website
            www.Gravel.com and the services and features we make available
            through our website, such as blog articles, e-newsletter
            subscription, and our contact form.  This Privacy Policy does not
            apply to information or data that we may receive from clients in
            connection with a client engagement, and our processing, use,
            storage and disclosure of such information is subject to other
            policies.  If you have any questions about our use of such
            information or wish to communicate with our senior security official
            or senior privacy official, please do not hesitate to contact us by
            email at 
            <a href="mailto:connect@Gravelerc.com">connect@Gravelerc.com</a>.
          </p>
          <p>
            We may update or modify this Privacy Policy at any time, by posting
            the amended version including the effective date of the updated
            version. By using the Services and accepting these terms, you agree
            to the terms of this Privacy Policy and the collection and use of
            information in accordance with this Policy. If you do not agree to
            be bound by this Privacy Policy or any subsequent modifications, you
            should not access or use our Services or disclose any personal
            information through our Services. Unless otherwise defined in this
            Privacy Policy, the terms used in this Privacy Policy have the same
            meanings as in our Terms of Use.
          </p>

          <p>
            This Privacy Policy contains the following information, which you
            can access by scrolling down:
          </p>
          <ol>
            <li>How to contact us.</li>
            <li>The information we collect about you.</li>
            <li>How we may use your information.</li>
            <li>Who we may share your information with.</li>
            <li>How we safeguard your personal information.</li>
            <li>How long we store your personal information.</li>
            <li>
              Your rights and options regarding your personal information.
            </li>
            <li>Special notice to non-U.S. users regarding data transfers.</li>
            <li>Sensitive Information.</li>
            <li>Children.</li>
            <li>Links.</li>
            <li>Governing law and jurisdiction.</li>
          </ol>
        </div>

        <div>
          <h2>1. HOW TO CONTACT US</h2>
          <p>
            You can update your preferences and information by updating your
            contact information through our Services or contacting us at the
            e-mail address or phone number below. Additionally, if you have any
            questions or concerns about our use of your Personal Information
            (defined below) or wish to communicate with us, please do not
            hesitate to contact us by email:
          </p>
          <p>
            Email:{' '}
            <a href="mailto:connect@gravelerc.com">connect@gravelerc.com</a>
          </p>
        </div>
        <div>
          <h2>2. PERSONAL INFORMATION</h2>
          <p>
            While using our Services, we may ask you to provide us with certain
            personally identifiable information (“Personal Information”). 
            Personal Information is information that identifies, relates to,
            describes, can reasonably be associated with, or can reasonably be
            linked to a particular individual or household.
          </p>
          <p>
            We collect information from you both when you provide it voluntarily
            and also automatically when you use our Services.  We may also
            collect Personal Information from other sources, as described below.
          </p>
          <p>
            We collect Personal Information from you and any devices (including
            mobile devices) you use when you use our Services, provide us
            information on a web form or when you otherwise correspond with us. 
            Please remember that any information that is disclosed in public
            comments, forums or other areas enabling other users to submit
            comments (“Forums”) becomes public information, is not maintained or
            treated as confidential, and you should exercise caution when
            deciding to disclose your Personal Information.
          </p>
          <p>
            In addition, we also collect Personal Information about you from
            third parties in connection with our Services, including from the
            following sources:
          </p>
          <ul>
            <li>Our customers when we provide services for our customers</li>
            <li>Service providers (including hosting providers)</li>
            <li>Data analytics service providers</li>
          </ul>
          <h3>INFORMATION WE COLLECT ABOUT YOU</h3>
          <p>
            We may collect the following categories of Personal Information from
            you, depending on your interactions with our Services and the
            choices you make, as further described in the chart below:
          </p>
          <ul>
            <li>
              <strong>Identifiers.</strong> Your name, email address postal
              address, unique personal identifier, and Internet Protocol (IP)
              address.
            </li>
            <li>
              <strong>
                Internet or other electronic network activity information.
              </strong>{' '}
              For example:
            </li>
            <ul>
              <li>
                <strong>Browse history.</strong> Data about the webpages you
                visit.
              </li>
              <li>
                <strong>Search history.</strong> Data about the search terms you
                enter.
              </li>
              <li>
                <strong>Device, connectivity, and configuration data.</strong>{' '}
                Data about your device and nearby networks, including regional
                and language settings.
              </li>
            </ul>
            <li>
              <strong>Geolocation data.</strong> Data about your device’s
              location, which is inferred from your IP address and your postal
              address.
            </li>
            <li>
              <strong>Demographic data</strong> Data about you such as your age,
              country, preferred language and other data reflecting your
              consumer’s preferences, characteristics, psychological trends,
              preferences, predispositions, behavior, attitudes, intelligence,
              abilities, and aptitude
            </li>
          </ul>
        </div>

        <div>
          <h3>Information collected through use of our Services</h3>
          <table>
            <thead>
              <colgroup span="4"></colgroup>
              <tr>
                <th>Categories of Information</th>
                <th>Description of category</th>
                <th>How We Use Information</th>
                <th>Legal basis for the processing purpose</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Third Party Data</td>
                <td>
                  This includes both Personal Information and non-personally
                  identifiable data from our affiliates, customers, partners or
                  vendors, data brokers or public sources.
                </td>
                <td>
                  <ul>
                    <li>
                      To provide, maintain, personalize, and improve our
                      Services.
                    </li>
                    <li>To monitor the usage of our Services.</li>
                    <li>To gather analysis and assess trends and interests.</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Legitimate business interests</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td style={{ fontWeight: 'bold' }}>Location Information</td>
                <td>
                  We may collect general location information if you use
                  features on our Services that provide location-based services.
                </td>
                <td>
                  <ul>
                    <li>To provide you with location-based services.</li>
                    <li>To monitor the usage of our Services.</li>
                    <li>To gather analysis and assess trends and interests.</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Legitimate business interests</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td style={{ fontWeight: 'bold', justifySelf: 'flex-start' }}>
                  Usage Information
                </td>
                <td>
                  This can be Personal Information and non-Personal Information
                  that is collected about you when you are using our Services,
                  and this may include:
                  <ul>
                    <li>
                      Information about your interactions with our Services,
                      which includes the date and time of any information you
                      enter into our Services and your interactions with other
                      users of our Services and what content or features you
                      interacted with.
                    </li>
                    <li>
                      User content you post to our Services and your
                      interactions with our customer service team and other
                      users.
                    </li>
                    <li>
                      Technical data which may include URL information, cookie
                      data, web beacons and other tracking technology
                      information, your IP address, the types of devices you are
                      using to access or connect to our Services, unique device
                      IDs, device attributes, network connection type (e.g.,
                      WiFi, 3G, LTE, Bluetooth) and provider, network and device
                      performance, browser type, language, and operating system.
                      Further details about the technical data that is processed
                      by us can be found below.
                    </li>
                  </ul>
                  Our Services uses cookies, unique identifiers and similar
                  technologies to collect information over time and across
                  different websites when you use or visit our Services. We or
                  our third-party partners use common tracking tools to collect
                  information about the pages you view, our Services functions
                  that you access, and the buttons and icons you click.
                </td>
                <td>{/* empty row */}</td>
                <td>
                  <ul>
                    <li>Legitimate business interests</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td style={{ fontWeight: 'bold', justifySelf: 'flex-start' }}>
                  Anonymized Information
                </td>
                <td>
                  We use anonymized and aggregated information that may be
                  created or derived from your Personal Information or usage of
                  our Services for purposes that include data analysis,
                  improving our Services, advertising, and developing new
                  features and functionality within our Services.
                </td>
                <td>
                  <ul>
                    <li>
                      To provide, maintain, personalize, and improve our
                      Services.
                    </li>
                    <li>To monitor the usage of our Services.</li>
                    <li>To gather analysis and assess trends and interests.</li>
                    <li>To detect, prevent, and address technical issues.</li>
                    <li>
                      To help maintain the safety, security, and integrity of
                      our Services and technology assets.
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Legitimate business interests</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <h2>3. HOW WE MAY USE YOUR INFORMATION</h2>
          <p>
            We will only use your information for the purposes identified above,
            unless we reasonably consider that we need to use it for another
            reason and that reason is compatible with the original purpose
            described here. If we need to use your Personal Information for an
            unrelated purpose or if we change the basis on which we are
            processing your data, we will notify you and we will explain the
            legal basis which allows us to do so.
          </p>
          <p>
            Where we rely on “legitimate business interests” as the reason for
            processing your information, we have determined, acting reasonably
            and considering the circumstances, that we are able to process your
            Personal Information in accordance with data protection laws on the
            basis that we have a legitimate business interest.
          </p>
          <p>
            We have a legitimate business interest in processing your Personal
            Information as:
            <ul>
              <li>We need the information to respond to your inquiries;</li>
              <li>We need the information to send you information; and</li>
              <li>
                We would be unable to provide our Services without processing
                your information.
              </li>
            </ul>
          </p>
          <p>
            We consider that it is reasonable for us to process your Personal
            Information for the purposes of our legitimate interests outlined
            above as (a) we process your Personal Information only so far as is
            necessary for such purpose and (b) it can be reasonably expected for
            us to process your Personal Information in this way. In most cases,
            the information is being processed for your benefit as well as ours.
          </p>
          <p>
            If we wish to process your existing information for a new purpose
            other than as stated above, and you are located in the European
            Union, California or any other jurisdiction that has passed a
            similar data privacy law, we must inform you of that further
            processing and provide information surrounding your information’s
            use.
          </p>
        </div>
        <div>
          <h2>4. SHARING YOUR INFORMATION</h2>
          <p>
            We share non-Personal Information with third parties at our
            discretion. We do not sell your Personal Information or share your
            Personal Information for our commercial benefit. In connection with
            our Services, we may share your Personal Information with certain
            third parties who we engage to help us run our business and perform
            the services, including under the following circumstances:
            <ul>
              <li>
                Software and service providers we use to manage and process your
                information.
              </li>
              <li>Affiliates, subsidiaries, and clients.</li>
              <li>
                Google Analytics, a service provided by Google, Inc., to gather
                information about how you and other users engage with our
                Services. For more information about Google Analytics, please
                visit 
                <a href="www.google.com/policies/privacy/partners">
                  Google's Partner Privacy page.
                </a>
                .
              </li>
              <li>
                Other third parties that you expressly request us to share your
                Personal Information with.
              </li>
            </ul>
          </p>
          <p>
            Additionally, we will share your Personal Information with third
            parties where required by law, where it is necessary in connection
            with our Services or products, or where we have another legitimate
            interest in doing so.
          </p>
          <p>
            Most of the third parties with whom we share your Personal
            Information are located and store your information in the United
            States, although some may be located or store your information
            outside of the United States.
          </p>
          <p>
            If we are subject to a merger or acquisition with/by another
            company, we may share information with them in connection with the
            transaction.
          </p>
        </div>
        <div>
          <h2>5. HOW WE SAFEGUARD YOUR INFORMATION</h2>
          <p>
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Information, we cannot
            guarantee its absolute security. Any transmission of Personal
            Information is at your own risk.
          </p>
        </div>
        <div>
          <h2>6. HOW LONG WE STORE YOUR INFORMATION</h2>
          <p>
            We will retain your Personal Information only for as long as is
            necessary for the legitimate business purposes set out in this
            Privacy Policy. We will retain and use your Personal Information to
            the extent necessary to comply with our legal, accounting, or
            reporting obligations (for example, if we are required to retain
            your data to comply with applicable laws), resolve disputes and
            enforce our legal agreements and policies. Additionally, we may
            continue to store your Personal Information contained in our
            standard back-ups.
          </p>
          <p>
            We also will retain Usage Information for internal analysis
            purposes. Usage Information is generally retained for a shorter
            period of time, except when Usage Information is used to strengthen
            the security or to improve the functionality of our Services or
            products, or we are legally obligated to retain Usage Information
            for longer periods.
          </p>
        </div>
        <div>
          <h2>7. YOUR RIGHTS AND CHOICES REGARDING YOUR INFORMATION</h2>
          <p>
            Marketing Preferences. You can opt out from receiving future
            marketing communications from us at any time by using the
            unsubscribe function in the email you receive from us, or contacting
            us as set forth under “How to contact us” above. Please allow
            sufficient time for your preferences to be processed. Even if you
            opt out of receiving marketing messages, we may still contact you
            for transactional purposes like confirming or following up on a
            service request, or asking you to review a service you have used. If
            you later opt back into getting marketing communications from us, we
            will remove your information from our opt-out databases.
          </p>
          <p>
            As noted above in “The Information We Collect About You,” you can
            choose whether to accept cookies by changing the settings on your
            browser or device. However, if you choose to disable cookies, your
            experience with our Services may be impaired and some features may
            not work as they were intended.
          </p>
          <p>
            Additionally, Do Not Track is a preference you can set in your web
            browser to inform websites that you do not want to be tracked. You
            can enable or disable Do Not Track by visiting the preferences or
            settings page of your web browser. However, these features are not
            yet uniform, so we do not currently respond to such features or
            signals. Therefore, if you select or turn on a “do not track”
            feature in your web browser, we and our third-party providers may
            continue collecting information about your online activities as
            described in this Privacy Policy.
          </p>
          <p>
            If you are located in the European Union, you have the following
            rights with respect to your data under certain circumstances:
            <ul>
              <li>
                The <strong>right to request access</strong> to your Personal
                Information and the following information regarding our use of
                your Personal Information:
                <ul>
                  <li>The purpose of such use</li>
                  <li>
                    The categories of your Personal Information that we have
                    used
                  </li>
                  <li>Who we have disclosed your Personal Information to</li>
                </ul>
              </li>
              <li>
                The <strong>right to request us to correct</strong> any
                inaccurate Personal Information concerning you.
              </li>
              <li>
                The <strong>right to request us to erase</strong> your Personal
                Information when we no longer need such data in connection with
                our services or, with respect to “special categories” of
                Personal Information, if you withdraw consent to our use of such
                information.
              </li>
              <li>
                The <strong>right to withdraw consent</strong> to our use of
                Personal Information, if we are relying on consent to use your
                Personal Information.
              </li>
              <li>
                The <strong>right to request us to restrict</strong> our use of
                your Personal Information (e.g., suspend our use of your
                Personal Information).
              </li>
              <li>
                The <strong>right to object</strong> to our use of your Personal
                Information where we are not using it to perform Services you
                have requested from us.
              </li>
              <li>
                The <strong>right to receive</strong> a copy of your Personal
                Information.
              </li>
              <li>
                The <strong>right to lodge a complaint</strong> with the
                supervising authority of your country.
              </li>
            </ul>
          </p>
          <p>
            If you want to exercise any of the rights listed above, please
            contact us through one of the methods listed in Section 1 above.
          </p>
          <p>
            If you are located in the United States, depending on where you
            live, you may have certain rights with respect to Personal
            Information that we have collected and used under certain
            circumstances, which may include the following:
            <ul>
              <li>
                The right to request the following information regarding our use
                of your Personal Information:
                <ul>
                  <li>
                    The categories of your Personal Information that we have
                    collected
                  </li>
                  <li>
                    The purposes for which we have collected or used such
                    Personal Information
                  </li>
                  <li>
                    The categories of sources for the Personal Information we
                    have collected about you
                  </li>
                  <li>
                    The categories of third parties with whom we have disclosed
                    your Personal Information
                  </li>
                  <li>
                    f we have disclosed your Personal Information for a business
                    purpose, a list identifying the Personal Information
                    categories that each category of recipient obtained
                  </li>
                  <li>
                    If we have sold your personal information, a list
                    identifying the Personal Information categories that each
                    category of recipient purchased
                  </li>
                </ul>
              </li>
              <li>The right to receive a copy of your Personal Information.</li>
              <li>
                The right to request us to erase your Personal Information when
                we no longer need such data in connection with our Services
                (subject to certain exceptions that may apply under applicable
                law).
              </li>
            </ul>
          </p>
          <p>
            If you choose to exercise your CCPA rights, we will not charge you
            different prices or provide different qualify of services unless
            those differences are related to your personal information or
            otherwise permitted by law.
          </p>
          <p>
            * If you are a resident of a jurisdiction where one or more of these
            rights are granted to you under applicable law, and you wish to
            exercise any such rights listed above, please contact us by email:
            <ul>
              <li>
                Email: 
                <a href="mailto:connect@gravel.com">connect@gravel.com</a>
              </li>
            </ul>
          </p>
          <p>
            Only you, or someone legally authorized to act on your behalf, may
            make a verifiable consumer request related to your Personal
            Information. Please understand that we are required to verify your
            request and may require you to provide some information to enable us
            to carry out such verification. We cannot respond to your request or
            provide you with Personal Information if we cannot verify your
            identity or authority to make the request and confirm the Personal
            Information relates to you.
          </p>
          <p>
            Making a verifiable consumer request does not require you to create
            an account with us. We will only use Personal Information provided
            in a verifiable consumer request to verify the requestor’s identity
            or authority to make the request.
          </p>
        </div>
        <div>
          <h2>8. TRANSFER OF DATA; SPECIAL NOTICE TO NON-U.S. USERS</h2>
          <p>
            Your information, including Personal Information, may be transferred
            to – and maintained on – computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction. If you
            are located outside United States and choose to provide information
            to us, please note that we transfer the data, including Personal
            Information, to the United States and process it there. Your consent
            to this Privacy Policy followed by your submission of such
            information represents your agreement to that transfer.
          </p>
          <p>
            We will take all the steps reasonably necessary to ensure that your
            data is treated securely and in accordance with this Privacy Policy
            and no transfer of your Personal Information will take place to an
            organization or a country unless there are adequate controls in
            place including the security of your data and other Personal
            Information.
          </p>
          <p>
            <strong>
              If you are located in a country outside the U.S. and submit
              Personal Information to us, you consent to the general use and
              disclosure of such information as provided in this Privacy Policy
              and to the transfer and/or storage of that information to the U.S.
              and other countries outside your home country.
            </strong>
          </p>
        </div>
        <div>
          <h2>9. SENSITIVE PERSONAL INFORMATION</h2>
          <p>
            Subject to the following paragraph, we ask that you not send us, and
            you not disclose, any sensitive personal information as this term is
            defined under applicable data protection and privacy laws (for
            example, social security numbers, information related to racial or
            ethnic origin, political opinions, religion or other beliefs,
            health, biometrics or genetic characteristics, criminal background
            or trade union membership) on or through the Services or otherwise
            to us.
          </p>
          <p>
            If you send or disclose any sensitive personal information to us,
            you consent to our processing and use of such sensitive personal
            data in accordance with this Privacy Policy. If you do not consent
            to our processing and use of such sensitive personal information,
            you must not submit such content to our Services.
          </p>
        </div>
        <div>
          <h2>10. POLICY ON CHILDREN’S INFORMATION</h2>
          <p>
            Our Services are not created for anyone under the age of 13
            (“Children” or “Child”). By using the Services, you represent that
            you are at least 13 years old.  If you do not meet this age
            requirement, then you must not access or use our Services.
          </p>
          <p>
            We do not knowingly collect personally identifiable information from
            anyone under the age of 14, and we do not target the Services to
            children under the age of 13. If you are a parent or guardian and
            you are aware that your Child has provided us with Personal
            Information, please contact us though one of the methods listed
            under “How to Contact Us”, above. If we become aware that we have
            collected Personal Information from Children without verification of
            parental consent, we take reasonable steps to remove that
            information from our servers.
          </p>
          <p>
            For more information about the Children’s Online Privacy Protection
            Act (“COPPA”), which applies to websites that direct their services
            to children under the age of thirteen (13), please visit 
            <a href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions">
              the Federal Trade Commission’s website.
            </a>
          </p>
        </div>
        <div>
          <h2>11. LINKS TO OTHER SITES</h2>
          <p>
            Our Services contain links to third-party websites. If you click on
            one of those links, you will be taken to websites we do not control.
            This Privacy Policy does not apply to the information practices of
            those third-party websites. You should read the privacy policies of
            third-party websites carefully. We are not responsible for the
            content, privacy policies, actions or security of third-party
            websites.
          </p>
        </div>
        <div>
          <h2>12. GOVERNING LAW AND JURISDICTION</h2>
          <p>
            This Privacy Policy shall be construed and governed under the laws
            of the United States and State of Tennessee (without regard to rules
            governing conflicts of laws provisions). You agree that venue for
            all actions, arising out of or relating in any way to your use of
            our Services, shall be in federal or state court of competent
            jurisdiction located in [Denver, Colorado], within one (1) year
            after the claim arises. Each party waives any objections based on
            forum non conveniens and waives any objection to venue of any action
            instituted hereunder to the extent that an action is brought in the
            courts identified above. Each party consents to personal
            jurisdiction in the courts identified above.
          </p>
        </div>
      </Wrapper>
    </>
  );
};

export default PrivacyPolicy;
