import React from 'react';
import ItemWrapper from "../ItemWrapper"
import TARGET from '../../../assets/target-covid-response.png';

const OperationalAssessmentAndSupport = () =>{

  const items = [
    {
      bold:"Marketing",
      copy: ""
    },
    {
      bold:"Culinary Systems and Menu Development",
      copy: ""
    },
    {
      bold:"Start or Restart Analysis and Planning",
      copy: ""
    },
    {
      bold:"Media Response Management",
      copy: "and spokesman service"
    },
    {
      bold:"Establishment of Internal Crisis Systems",
      specialCopy:"set-up",
      copy: "and/or ongoing management including contingency/business continuity planning"
    },

  ]
  return <ItemWrapper image={TARGET} alt='target icon' heading='OPERATIONAL ASSESSMENT & SUPPORT' items={items}  />
}

export default OperationalAssessmentAndSupport
