import React from 'react';
import styled from '@emotion/styled';
import { colors, SpaceScale, breakpoints, FontScale, fonts } from '../../theme';
import logo from '../../assets/gravel-mark.svg';
import FooterLinks from './FooterLinks';

const Wrapper = styled.footer`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightGrey};
  flex-direction: column;

  ${breakpoints.mobile} {
    padding: ${SpaceScale(4)};
    padding-bottom: ${SpaceScale(4.5)};
  }
  ${breakpoints.tablet} {
    flex-direction: row;
  }

  a {
    text-decoration: none;
    color: black;
    padding: 0 ${SpaceScale(3)};
  }

  div {
    display: flex;
  }

  ${breakpoints.mobile} {
    padding-top: ${SpaceScale(6)};
  }

  ${breakpoints.tablet} {
    padding: ${SpaceScale(5)};
    padding-bottom: ${SpaceScale(6)};
  }
`;

const Brand = styled.div`
  position: absolute;
  bottom: 25px;
  left: 5px;

  p {
    align-self: flex-end;
    padding: 0;
    margin: 0 0 -10px -15px;
    font-size: ${FontScale(3)};
  }
  img {
    max-width: 50px;
  }
  ${breakpoints.desktop} {
    img {
      max-width: 100%;
    }
    bottom: 35px;
    left: 35px;
  }
`;

const Credit = styled.p`
  position: absolute;
  font-size: ${FontScale(3)};
  font-family: ${fonts.serif};
  padding: 0;
  margin: 0;
  bottom: 15px;
  /* right: 0; */
  font-weight: normal;

  ${breakpoints.mobile} {
    bottom: 5px;
    right: 37%;
    font-weight: normal;
  }
`;

const Footer = () => {
  // const [isTablet, setTablet] = useState(window.innerWidth > 767);

  // const updateMedia = () => {
  //   setTablet(window.innerWidth > 767);
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", updateMedia);
  //   return () => window.removeEventListener("resize", updateMedia);
  // });
  return (
    <>
      <Wrapper>
        <Brand>
          <a href="/#top">
            <img src={logo} alt="Gravel logo" width="64px" />
          </a>
        </Brand>
        <FooterLinks />
        <Credit>
          <a
            href="https://brandon-leboeuf.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            built by &#9399;randon
          </a>
        </Credit>
      </Wrapper>
    </>
  );
};

export default Footer;
