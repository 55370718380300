import React from 'react';
import ItemWrapper from "../ItemWrapper"
import LIST from '../../../assets/list-project-mgmt.png';

const MergerAndAcquisitionSupport = () =>{

  const items = [
    {
      bold:"Problem Assessment and Analysis",
      copy: "with solution proposals"
    },
    {
      bold:"Short-Range Planning & Facilitation",
      copy: ""
    },
    {
      bold:"Long-Range Planning & Facilitation",
      copy: ""
    },
    {
      bold:"Program Rollout Planning & Implementation",
      copy: ""
    },
    {
      bold:"Technology & Solution Integration",
      copy: ""
    },
    {
      bold:"Project Management",
      copy: ""
    },
    {
      bold:"Program Effectiveness",
      copy: "- Measurement of expected results"
    },

  ]
  return <ItemWrapper image={LIST} alt='money changing hands icon' heading='STRATEGIC PLANNING' items={items}  />
}

export default MergerAndAcquisitionSupport
