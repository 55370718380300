import React from 'react';
import HEADER_PLACEHOLDER from './HEADER_PLACEHOILDER/';
import Wrapper from './HEADER_PLACEHOILDER/Wrapper';

const Terms = () => {
  return (
    <>
      <HEADER_PLACEHOLDER />
      <Wrapper>
        <div id="top">
          <h1>Terms & Conditions</h1>
          <h2>Personal Identification Information</h2>
          <p>
            Gravel Executive Resource Consulting, LLC may collect personal
            identification information from Users in a variety of ways,
            including, but not limited to, when Users visit our site, fill out a
            form respond to a survey subscribe to the newsletter and in
            connection with other activities, services, features or resources
            Gravel Executive Resource Consulting, LLC makes available on our
            Site. Users may be asked for, as appropriate, name, email address,
            mailing address, phone number.
          </p>
          <p>Users may, however, visit our Site anonymously.</p>
          <p>
            Gravel Executive Resource Consulting, LLC will collect personal
            identification information from Users only if they voluntarily
            submit such information to us. Users can always refuse to supply
            personal identification information, except that it may prevent them
            from engaging in certain Site related activities.
          </p>
        </div>
        <div>
          <h2>Non-Personal Identification Information</h2>
          <p>
            Gravel Executive Resource Consulting, LLC may collect non-personal
            identification information about Users whenever they interact with
            our Site. Non-personal identification information may include the
            browser name, the type of computer and technical information about
            Users means of connection to our Site, such as the operating system
            and the Internet service providers utilized and other similar
            information.
          </p>
        </div>
        <div>
          <h2>Web Browser Cookies</h2>
          <p>
            Our Site may use “cookies” to enhance User experience. User’s web
            browser places cookies on their hard drive for record-keeping
            purposes and sometimes to track information about them. User may
            choose to set their web browser to refuse cookies, or to alert you
            when cookies are being sent. If they do so, note that some parts of
            the Site may not function properly.
          </p>
        </div>
        <div>
          <h2>
            How Gravel Executive Resource Consulting, LLC Uses Collected
            Information
          </h2>
          <p>
            Gravel Executive Resource Consulting, LLC collects and uses Users
            personal information for the following purposes:
            <ul>
              <li>
                <em>To improve customer service</em> <br />
                Your information helps us to more effectively respond to your
                customer service requests and support needs
              </li>
              <li>
                <em>To personalize user experience</em> <br />
                Gravel Executive Resource Consulting, LLC may use information in
                the aggregate to understand how our Users as a group use the
                services and resources provided on our Site.
              </li>
              <li>
                <em>To improve our Site</em> <br />
                Gravel Executive Resource Consulting, LLC continually strive to
                improve our website offerings based on the information and
                feedback Gravel Executive Resource Consulting, LLC receives from
                you.
              </li>
              <li>
                <em>To send periodic emails</em> <br />
                The email address Users provide for order processing, will only
                be used to send them information and updates pertaining to their
                order. It may also be used to respond to their inquiries, and/or
                other requests or questions. If User decides to opt-in to our
                mailing list, they will receive emails that may include company
                news, updates, related product or service information, etc. If
                at any time the User would like to unsubscribe from receiving
                future emails, Gravel Executive Resource Consulting, LLC
                includes detailed unsubscribe instructions at the bottom of each
                email or User may contact us via our Site.
              </li>
            </ul>
          </p>
        </div>
        <div>
          <h2>
            How Gravel Executive Resource Consulting, LLC Protects Your
            Information
          </h2>
          <p>
            Gravel Executive Resource Consulting, LLC adopts appropriate data
            collection, storage and processing practices and security measures
            to protect against unauthorized access, alteration, disclosure or
            destruction of your personal information, username, password,
            transaction information and data stored on our Site.
          </p>
          <p>
            Sensitive and private data exchange between the Site and its Users
            happens over a SSL secured communication channel and is encrypted
            and protected with digital signatures.
          </p>
        </div>
        <div>
          <h2>Sharing Your Personal Information</h2>
          <p>
            Gravel Executive Resource Consulting, LLC does not sell, trade, or
            rent Users personal identification information to others. Gravel
            Executive Resource Consulting, LLC may share generic aggregated
            demographic information not linked to any personal identification
            information regarding visitors and users with our business partners,
            trusted affiliates and advertisers for the purposes outlined above.
            Gravel Executive Resource Consulting, LLC may use third party
            service providers to help us operate our business and the Site or
            administer activities on our behalf, such as sending out newsletters
            or surveys. Gravel Executive Resource Consulting, LLC may share your
            information with these third parties for those limited purposes
            provided that you have given us your permission.
          </p>
        </div>
        <div>
          <h2>Changes to This Privacy Policy</h2>
          <p>
            Gravel Executive Resource Consulting, LLC has the discretion to
            update this privacy policy at any time. When Gravel Executive
            Resource Consulting, LLC does, post a notification on the main page
            of our Site, revise the updated date at the bottom of this page.
            Gravel Executive Resource Consulting, LLC encourages Users to
            frequently check this page for any changes to stay informed about
            how Gravel Executive Resource Consulting, LLC is helping to protect
            the personal information Gravel Executive Resource Consulting, LLC
            collects. You acknowledge and agree that it is your responsibility
            to review this privacy policy periodically and become aware of
            modifications.
          </p>
        </div>
        <div style={{ alignSelf: 'flex-start' }}>
          <h2>Permission-Based Marketing Policy</h2>
          <p>
            Gravel Executive Resource Consulting, LLC supports only
            permission-based email marketing.{' '}
          </p>
        </div>
        <div>
          <h2>Your Acceptance of These Terms</h2>
          <p>
            By using this Site, you signify your acceptance of this policy and 
            <a href="/PrivacyPolicy">privacy policy</a>. If you do not agree to
            this policy, please do not use our Site. Your continued use of the
            Site following the posting of changes to this policy will be deemed
            your acceptance of those changes.
          </p>
        </div>
        <div>
          <h2>Contacting Us</h2>
          <p>
            If you have any questions about this terms and conditions, the
            practices of this site, or your dealings with this site, please
            contact us at:
          </p>
          <p>Gravel Executive Resource Consulting, LLC</p>
          <p>
            10080 E 26th Ave <br />
            Aurora, CO 80010
            <br />
            connect@gravelerc.com
            <br />
            Gravelerc.com
          </p>
        </div>
      </Wrapper>
    </>
  );
};

export default Terms;
