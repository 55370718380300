import styled from '@emotion/styled';

import { SpaceScale, breakpoints, FontScale } from '../../theme';
import { colors, fonts } from '../../theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px;
  border-radius: 10px;

  ${breakpoints.tablet} {
    justify-content: center;
    flex-direction: row;
    margin-top: 100px;
    padding: 50px 0;
    box-shadow: 4px 3px ${colors.lightGrey};
  }

  ${breakpoints.desktop} {
    min-height: 75vh;
  }
`;

export const IMGWrapper = styled.div`
  ${breakpoints.tablet} {
    /* margin: 0 50px 100px 50px; */
    width: 50%;
  }

  img {
    border-radius: 5px;
    max-width: 250px;

    ${breakpoints.tablet} {
      max-width: 500px;
    }
  }
`;

export const Copy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: ${colors.orange};
    margin-bottom: 0;
    padding-bottom: 0;
  }

  h3 {
    margin: 5px 0;
    font-size: ${FontScale(4)};
  }

  p {
    text-align: left;
    margin-top: 5px;
    padding-top: 0;
    font-family: ${fonts.sans};
  }

  div:nth-of-type(2n) {
    border-top: 1px solid ${colors.orange};
    padding-top: 20px;
  }
  ${breakpoints.tablet} {
    width: 50%;
    margin: 0 50px;
  }
`;

export const SocialButtons = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${SpaceScale(4)};

  a {
    text-decoration: none;
    color: black;
    padding: ${SpaceScale(2)};
  }
`;
