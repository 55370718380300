import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, colors, SpaceScale } from '../../theme';
import logo from '../../assets/gravel-on-navy.svg';
// import logoMobile from "../../assets/gravel-mark.svg"

const Holder = styled.div`
  background-color: ${colors.black};
  height: 40px;

  ${breakpoints.tablet} {
    height: 170px;
    margin-top: -170px;
  }
`;

const Brand = styled.div`
  /* position: fixed;
  top: 100px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: flex-start;
  margin-top: -70px;
  z-index: 100;

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: inherit;
  }

  div {
    display: flex;
    align-items: flex-end;
  }

  img {
    max-width: 200px;
  }

  ${breakpoints.mobile} {
    padding: 20px;
    width: 100vw;
    background-color: ${colors.black};
    margin-top: -10px;
    /* align-items: center; */

    img {
      max-width: 100px;
      margin-left: 30vw;
    }
  }

  ${breakpoints.tablet} {
    margin-left: ${SpaceScale(5)};
  }
`;

const HEADER_PLACEHOLDER = () => {
  return (
    <>
      <Holder />
      <Brand>
        <a href="/#about-us" style={{ zIndex: '100' }}>
          <div>
            <img src={logo} alt="Gravel logo" style={{ minWidth: '200px' }} />
          </div>
        </a>
      </Brand>
    </>
  );
};

export default HEADER_PLACEHOLDER;
