const colors = {
  // FROM ORIGIONAL SPLASH PAGE
  // orange: "#d67049",
  // black: '#242832',
  // white: "#ffffff",
  // darkGrey: "#969a9e",
  // lightGrey: '#eff5fb',
  
  // FROM STYLE GUIDE
  // orange: "#D56D44", passes a11y
  orange: "#d77045", // fails a11y
  black: '#242832',
  white: "#ffffff",
  darkGrey: "#4c4c4e",
  lightGrey: '#f1f1f2',
  lightHover: '#f1f1f263',
  form: "#E8EEF7"
};

export default colors;


