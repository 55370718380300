import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colors, SpaceScale, breakpoints, fonts } from '../../theme';

const CTA = styled.button`
  border: 1px solid ${colors.orange};
  border-radius: 4px;
  padding: ${SpaceScale(3)};
  font-family: ${fonts.sans};
  background: transparent;
  text-transform: capitalize;
  cursor: pointer;
  color: ${colors.orange};
  font-weight: bold;
  transition: 0.2s;
  text-transform: uppercase;
  outline: none;

  a {
    text-decoration: none;
    color: ${colors.orange};
    padding: 0;
    margin: 0;
  }

  &:hover {
    background: ${colors.orange};
    color: ${colors.white};
    transform: scale(1.03);
  }

  ${(nature) =>
    nature === 'ghost' &&
    css`
      transition: all 200ms ease;
      color: ${colors.white};
      background: ${colors.orange};
      border: 1.5px solid ${colors.darkGrey};

      &:hover {
        color: ${colors.pink};
        background-color: ${colors.white};
      }
    `}

  ${breakpoints.tablet} {
    padding: ${SpaceScale(3)} ${SpaceScale(5)};
  }
`;

const Button = (props) => {
  return (
    <a href="/#form">
      <CTA nature={props.nature}>{props.text}</CTA>
    </a>
  );
};

export default Button;
