import React from 'react';
import ItemWrapper from "../ItemWrapper"
import PEOPLE from '../../../assets/people-human-rsc.png'

const OrganizationalDevelopment = () =>{

  const items = [
    {
      bold:"Cultural Design",
      copy: "and Implementation"
    },
    {
      bold:"Process & System Documentation",
      copy: "- Discovery and implementation of best practices across the organization"
    },
    {
      bold:"Recruitment Design",
      copy: ""
    },
    {
      bold:"Talent Assessment",
      copy: ""
    },
    {
      bold:"Departmental Proficiency Assessment",
      copy: ""
    },
    {
      bold:"Leadership Development",
      copy: ""
    },
    {
      bold:"Training",
      copy: "- Needs assessments, session design, curriculum design, class design including assessment and optimization for any current programs"
    },
    {
      bold:"Equity, Diversity & Inclusion ",
      copy: "- Bias discovery and training, Anti-racism policy and principle establishment"
    },
    {
      bold:"Organizational Design & Step-Change Planning",
      copy: ""
    },
  ]
  return <ItemWrapper image={PEOPLE} alt={'people icon'} heading={'ORGANIZATIONAL DEVELOPMENT / HR'} items={items}  />
}

export default OrganizationalDevelopment
