import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { FontScale, breakpoints, fonts, colors } from '../../../theme';

const Links = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${FontScale(2.5)};

  div a {
    font-weight: bold;
  }
`;

const Copy = styled.div`
  display: flex;
  justify-content: center;
  color: ${colors.darkGrey};
  line-height: 1.5;
  font-family: ${fonts.serif};
  font-size: ${FontScale(3)};
  margin-bottom: 20px;

  ${breakpoints.tablet} {
    font-size: ${FontScale(4)};
  }
`;

const FooterLinks = () => {
  return (
    <Links>
      <Copy>
        {/* <a href="https://twitter.com/Gravel_erc" target="_blank" rel="noopener noreferrer" aria-label="Link to Gravel's Twitter"><i className="fab fa-twitter-square"></i></a> */}
        <a
          href="https://www.facebook.com/gravelerc/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Link to Gravel's Facebook"
        >
          <i className="fab fa-facebook-square"></i>
        </a>
        <a
          href="https://www.linkedin.com/company/gravel-executive-resource-consulting/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Link to Gravel's LinkedIn"
        >
          <i className="fab fa-linkedin"></i>
        </a>
        <a
          href="mailto:connect@gravelerc.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Link to Gravel's Email"
        >
          <i className="far fa-envelope"></i>
        </a>
      </Copy>
      <div>
        <a href="/#form">Contact</a>
        <Link to="/Terms">Terms Of Service</Link>
        <Link to="/PrivacyPolicy">Privacy Policy</Link>
      </div>
      <p>
        &copy; 2013-{new Date().getFullYear()} Gravel ERC, All rights reserved.
      </p>
    </Links>
  );
};

export default FooterLinks;
